.invite-top-bg {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  background-repeat: no-repeat;
  background-image: url('/images/invite/bg.png');
  background-size: cover;
}

.invite-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 0.6fr;
  row-gap: 16px;
  justify-content: space-between;
}

.invite-grid-title {
  color: #848e9c;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.invite-grid-content {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.invite-grid-left-title {
  color: #848e9c;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.invite-detail-history {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.invite-detail-history-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.invite-detail-history-title {
  color: #848e9c;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
