.action-btn {
  position: relative;
  width: 50%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  border-radius: 4px;
  color: #777e90;
  transition: color 0.2s ease-in-out;
}

.action-btn.active {
  color: #fff;
}

.profit-tips strong,
.loss-tips strong {
  font-weight: 500;
}

.profit-tips strong:first-child,
.loss-tips strong:first-child {
  color: #fff;
}

.profit-tips strong:last-child {
  color: #58bd7d;
}

.loss-tips strong:last-child {
  color: #fe6662;
}

.long-btn {
  background: url('/images/trade/short.svg') center no-repeat;
  background-size: cover;
  transform: background-image rotate(180deg);
}

.long-btn.active {
  background: url('/images/trade/long-active.svg') center no-repeat;
  background-size: cover;
}

.short-btn {
  background: url('/images/trade/short.svg') center no-repeat;
  background-size: cover;
}

.short-btn.active {
  background: url('/images/trade/short-active.svg') center no-repeat;
  background-size: cover;
}
