@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body,
[data-overlay-container='true'] {
  width: 100%;
  font-family:
    PingFang SC,
    Rubik,
    Helvetica Neue,
    Helvetica,
    sans-serif;
}

#__next {
  height: 100%;
}

[data-overlay-container='true'] {
  display: flex;
  flex-direction: column;
}

.font-10 {
  font-size: 12px;
  zoom: 0.83;
}

.position-grid {
  display: grid;
  grid-template-columns: 43% 35% 22%;
  grid-template-rows: 38px;
}

.ended-grid {
  display: grid;
  grid-template-columns: 34% 22% 34%;
  grid-template-rows: 38px;
}

.pool-grid {
  display: grid;
  grid-template-columns: 54% 54%;
  grid-template-rows: 32px;
}

.dashboard-list-grid {
  display: grid;
  grid-template-columns: 36.33% 31.33% 29.33%;
  grid-template-rows: 36px;
}

.pool-drop li {
  padding-top: 0;
  padding-bottom: 0;
}

.scale-half {
  display: flex;
  height: 1px;
  transform: scaleY(0.5);
  transform-origin: 50% 100%;
  background: #eee;
}

.pool-drop li:last-child .scale-half {
  display: none;
}

.ios-text-question-media {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
