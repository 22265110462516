.dashboard-header {
  position: relative;
  background: linear-gradient(
    125deg,
    #e7e8ff 3.31%,
    #f1eeff 43.75%,
    #ebe6ff 65.86%,
    #eae5ff 81.39%,
    #f0ecff 92.64%
  );
}

.dashboard-header::before {
  content: '';
  position: absolute;
  top: 0;
  right: -194px;
  background: url('../../public/images/dashboard/header-bg.svg') no-repeat center;
  width: 531px;
  height: 121px;
  background-size: 100% 100%;
}

.dashboard-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  background: url('../../public/images/dashboard/header-bg-bottom.svg') no-repeat center;
  width: 531px;
  height: 121px;
  left: -183px;
  background-size: 100% 100%;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 37% 32% 20%;
  gap: 16px;
  position: relative;
  z-index: 9;
}

.pool-item {
  width: calc((100% - 48px) / 2);
}
